<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Producto - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-tabs content-class="mt-3">
                <b-tab title="Producto" active>
                  <b-row>

                    <b-col md="3">
                      <div class="w-100 text-center">
                        <img class="img-fluid" :src="previewImage">
                      </div>
                      <b-col md="12">
                        <b-form-group label="Foto: ">
                          <b-form-file @change="PreviewImage" accept="image/*" v-model="photo" placeholder="Seleccione un foto..." drop-placeholder="Suelta la imagen aquí..."></b-form-file>
                          <small class="form-text text-center text-secondary">Tamaño recomendado 400px x 500px</small>
                        </b-form-group>
                      </b-col>
                    </b-col>

                    <b-col md="9">
                      <b-row>
                          <b-col md="3">
                            <b-form-group label="Categoria :">
                              <b-form-select v-model="product.id_category_product" :options="categories"></b-form-select>
                              <small v-if="errors.id_category_product" class="form-text text-danger">Seleccione una categoria</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Codigo:">
                              <b-form-input readonly type="text" class="text-center" v-model="product.code"></b-form-input>
                              <small v-if="errors.code" class="form-text text-danger" >Ingrese un código</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Nombre:">
                              <b-form-input  type="text" v-model="product.name" ></b-form-input>
                              <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                            </b-form-group>
                          </b-col>

                          

                          <b-col md="3">
                            <b-form-group label="Presentación :">
                              <b-form-input  type="text" ref="presentation" v-model="product.presentation" ></b-form-input>
                              <small v-if="errors.presentation" class="form-text text-danger">Ingrese una presentacion</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Unidad de Medida :">
                              <b-form-select v-model="product.unit_measure" :options="unit_measure"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Descripción:">
                              <b-form-input ref="description"  v-model="product.description"></b-form-input>
                            </b-form-group>
                          </b-col>

      
                          <b-col md="3">
                            <b-form-group label="Codigo de Barras:">
                              <b-form-input type="text" v-model="product.barcode"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Tipo de afectación :">
                              <b-form-select v-model="product.igv" :options="igv"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Producto Interno :">
                              <b-form-select type="text" v-model="product.internal_product" :options="internal_product"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Control de Stock? :">
                              <b-form-select v-model="product.stock" :options="stock"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Precio Compra:">
                              <b-form-input type="number" step="any" class="text-right" v-model="product.purchase_price"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col md="9">
                            <b-form-group label="Proveedor :">
                            <v-select placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="mprovider" @search="SearchProvider" :options="providers"></v-select>
                            <small v-if="errors.id_provider" class="form-text text-danger">Seleccione un proveedor</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="3">
                            <b-form-group label="Estado :">
                              <b-form-select v-model="product.state" :options="state"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="5"></b-col>
                          <b-col md="2">
                            <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                          </b-col>


                      </b-row> 
                    </b-col>
                    
                  </b-row>
                </b-tab>
                <b-tab title="Productos Derivados" >
                   <b-row>
                     
                        

                        <b-col md="6">
                          <b-form-group label="Nombre:">
                            <b-form-input  type="text" v-model="product_supplies.name" ></b-form-input>
                            <small v-if="errors.product_supplies_name" class="form-text text-danger">Ingrese un nombre</small>
                          </b-form-group>
                        </b-col>

                        <b-col md="2">
                          <b-form-group label="Presentación :">
                            <b-form-input type="text" v-model="product_supplies.presentation" ></b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="2">
                          <b-form-group label="Unidad de Medida :">
                            <b-form-select v-model="product_supplies.unit_measure" :options="unit_measure"></b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col md="2">
                          <b-form-group label=".">
                            <b-button type="button" @click="ValidateProductSupplies" variant="primary" class="form-control">Agregar</b-button>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <div class="table-responsive mt-3 height-table">
                            <table class="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th width="5%" class="text-center">#</th>
                                  <th width="50%" class="text-center">Nombre</th>
                                  <th width="10%" class="text-center">Presentación</th>
                                  <th width="25%" class="text-center">U.M.</th>
                                  <th width="8%" class="text-center">Acciones</th>
                                </tr>
                              </thead>
                              <tbody v-for="(item, it) in products_supplies" :key="it">
                                <tr>
                                  <td class="text-center">{{ it + 1 }}</td>
                                  <td class="text-left">
                                    <b-form-input v-model="item.name"></b-form-input>
                                  </td>
                                  <td class="text-left"><b-form-input v-model="item.presentation"></b-form-input></td>
                                  <td class="text-left"><b-form-select v-model="item.unit_measure" :options="unit_measure"></b-form-select></td>
                                  <td class="text-center">
                                    <b-button @click="UpdateProductSupplies(it)" type="button" variant="warning" class="btn-sm" ><i class="fas fa-save"></i></b-button>
                                    <b-button @click="ConfirmDeleteProductSupplies(item.id_product_supplies)" type="button" variant="danger" class="btn-sm" ><i class="fas fa-trash-alt"></i></b-button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-col>

              

                   </b-row>
                </b-tab>
              </b-tabs>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "ProductEdit",
  props: ["id_product"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: "Product",
      role: 3,
      product: {
        id_product:"",
        id_category_product: "",
        id_provider: "",
        code: "",
        name: "",
        barcode: "",
        description: "",
        presentation: "",
        existence_type: "01",
        unit_measure: "NIU",
        igv: "10",
        internal_product: 0,
        photo: "",
        unspsc_code:'23251602',
        supplies: 0,
        minimal_price: 0.0,
        sale_price: 0.0,
        purchase_price: 0.0,
        stock: 1,
        state: 1,
      },
      product_supplies:{
        id_product_supplies:'',
        id_product:'',
        code:'',
        name:'',
        presentation:'',
        unit_measure:'NIU',
        state:'1',
      },
      products_supplies:[],
      mprovider :{id:1, name:'Proveedor varios'},
      providers: [],
      previewImage: 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg',
      categories: [],
      photo: null,
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      supplies:[
        {value:1,text:'SI'},
        {value:0,text:'NO'},
      ],
      igv:[
        {value:10,text:'Gravado'},
        {value:20,text:'Exonerado'},
        {value:30,text:'Inafecto'},
      ],
      unit_measure:[
        {value:'4A',text:'BOBINAS'},
        {value:'BJ',text:'BALDE'},
        {value:'BLL',text:'BARRILES'},
        {value:'BG',text:'BOLSA'},
        {value:'BO',text:'BOTELLAS'},
        {value:'BX',text:'CAJA'},
        {value:'CMK',text:'CENTIMETRO CUADRADO'},
        {value:'CMQ',text:'CENTIMETRO CUBICO'},
        {value:'CMT',text:'CENTIMETRO LINEAL'},
        {value:'CEN',text:'CIENTO DE UNIDADES'},
        {value:'CY',text:'CILINDRO'},
        {value:'DZN',text:'DOCENA'},
        {value:'DZP',text:'DOCENA POR 10**6'},
        {value:'BE',text:'FARDO'},
        {value:'GLI',text:'GALON INGLES (4,545956L)'},
        {value:'GRM',text:'GRAMO'},
        {value:'KGM',text:'KILOGRAMO'},
        {value:'CA',text:'LATAS'},
        {value:'LBR',text:'LIBRAS'},
        {value:'LTR',text:'LITRO'},
        {value:'MTR',text:'METRO'},
        {value:'MGM',text:'MILIGRAMOS'},
        {value:'MIL',text:'MILLARES'},
        {value:'UM',text:'MILLON DE UNIDADES'},
        {value:'ONZ',text:'ONZAS'},
        {value:'PF',text:'PALETAS'},
        {value:'PK',text:'PAQUETE'},
        {value:'GRM',text:'GRAMO'},
        {value:'PR',text:'PAR'},
        {value:'C62',text:'PIEZAS'},
        {value:'NIU',text:'UNIDAD (BIENES) '},
        {value:'ZZ',text:'UNIDAD (SERVICIOS)'},
        {value:'GRM',text:'US GALON (3,7843 L)'},
        {value:'GLL',text:'GRAMO'},
      ],
      stock :[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],                                               
      internal_product :[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],
      errors: {
        id_category_product: false,
        code: false,
        name: false,
        state: false,
        product_supplies_name:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListCategories();
    this.ViewProduct();
  },
  methods: {
    PreviewImage,
    ListCategories,
    ViewProduct,
    EditProduct,
    Validate,
    SearchProvider,

    ValidateProductSupplies,
    AddProductSupplies,
    DeleteProductSupplies,
    NameUnitMeasure,
    ConfirmDeleteProductSupplies,
    UpdateProductSupplies,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function PreviewImage(event) {
  var input = event.target;
  if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
          this.previewImage = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
  }
  this.product.photo = input.files[0];
}

function ListCategories() {
  let me = this;
  let url = this.url_base + "category-product/list-active";
  axios({
    method: "GET",
    url: url,
     headers: { token: this.token, module: this.module, role: this.role},
  })
  .then(function (response) {
    me.categories = [{value:'',text:'Seleccione una categoria'}];
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
          me.categories.push({value:element.id_category_product,text:element.name});
      }
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  });
}

function SearchProvider(search, loading) {
  
    let me = this;
    let url = this.url_base + "search-providers/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.providers = response.data.result;
            loading(false);
      })
    }
    
}


//ver usuario
function ViewProduct() {
  let id_product = je.decrypt(this.id_product);
  let me = this;
  let url = this.url_base + "product/view/" + id_product;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        
        me.product.id_product = response.data.result.id_product;
        me.product.id_category_product = response.data.result.id_category_product;
        me.product.id_provider = response.data.result.id_provider;
        me.product.code = response.data.result.code;
        me.product.name = response.data.result.name;
        me.product.barcode = response.data.result.barcode;
        me.product.description = response.data.result.description;
        me.product.presentation = response.data.result.presentation;
        me.product.existence_type = response.data.result.existence_type;
        me.product.unit_measure = response.data.result.unit_measure;
        me.product.igv = response.data.result.igv;
        me.product.internal_product = response.data.result.internal_product;
        me.product.photo = '';
        me.product.unspsc_code = response.data.result.unspsc_code;
        me.product.supplies = response.data.result.supplies;
        me.product.minimal_price = response.data.result.minimal_price;
        me.product.sale_price = response.data.result.sale_price;
        me.product.purchase_price = response.data.result.purchase_price;
        me.product.stock = response.data.result.stock;
        me.product.state = response.data.result.state;
        me.previewImage = me.url_base + response.data.result.photo;
        me.mprovider = {id: response.data.result.id_provider, name : response.data.result.provider_name + ' - '+ response.data.result.provider_document_number}
        me.products_supplies = response.data.products_supplies;

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditProduct() {

  let me = this;
  let url = me.url_base + "product/edit";
  let data = new FormData();
  data.append("id_product", me.product.id_product);
  data.append("id_category_product", this.product.id_category_product);
  data.append("id_provider", me.mprovider.id);
  data.append("code", this.product.code);
  data.append("name", this.product.name);
  data.append("barcode", this.product.barcode);
  data.append("description", this.product.description);
  data.append("presentation", this.product.presentation);
  data.append("existence_type", this.product.existence_type);
  data.append("unit_measure", this.product.unit_measure);
  data.append("igv", this.product.igv);
  data.append("internal_product", this.product.internal_product);
  data.append("photo", this.product.photo);
  data.append("unspsc_code", this.product.unspsc_code);
  data.append("minimal_price", this.product.minimal_price);
  data.append("sale_price", this.product.sale_price);
  data.append("purchase_price", this.product.purchase_price);
  data.append("supplies", this.product.supplies);
  data.append("stock", this.product.stock);
  data.append("state", this.product.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token,module: me.module,role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      Swal.fire({ icon: 'success', text: 'Se ha modificado el producto', timer: 3000,})
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
    me.isLoading = false;
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = false;
  });
}

//validacion de formulario
function Validate() {

  this.errors.id_category_product = this.product.id_category_product.length == 0 ? true : false;
  this.errors.id_provider = this.mprovider == null ? true : false;
  this.errors.name = this.product.name.length == 0 ? true : false;
  this.errors.code = this.product.code.length == 0 ? true : false;
  this.errors.minimal_price = this.product.minimal_price.length == 0 ? true : false;
  this.errors.sale_price = this.product.sale_price.length == 0 ? true : false;
  this.errors.state = this.product.state.length == 0 ? true : false;

  if (this.errors.id_category_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_provider) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.minimal_price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.sale_price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar el producto ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditProduct();
      }
    });

  }

}

function AddProductSupplies() {

  let me = this;
  let url = me.url_base + "product/add-product-supplies";
  let data = new FormData();
  data.append("id_product_supplies", me.product_supplies.id_product_supplies);
  data.append("id_product", this.product.id_product);
  data.append("code", this.product_supplies.code);
  data.append("name", this.product_supplies.name);
  data.append("presentation", this.product_supplies.presentation);
  data.append("unit_measure", this.product_supplies.unit_measure);
  data.append("state", this.product_supplies.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token,module: me.module,role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 201) {
        me.products_supplies.push(response.data.result);

        me.product_supplies.id_product_supplies = '';
        me.product_supplies.id_product = '';
        me.product_supplies.code = '';
        me.product_supplies.name = '';
        me.product_supplies.presentation = '';
        me.product_supplies.unit_measure = 'NIU';
        me.product_supplies.state = '1';
      Swal.fire({ icon: 'success', text: 'Se ha agregado el producto derivado', timer: 3000,})
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
    me.isLoading = false;
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = false;
  });



  

      
}

function ValidateProductSupplies() {

  this.errors.product_supplies_name = this.product_supplies.name.length == 0 ? true : false;
  if (this.errors.product_supplies_name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  this.AddProductSupplies();
}

function ConfirmDeleteProductSupplies(id_product_supplies) {
  Swal.fire({
    title: "Esta seguro de eliminar el producto derivado?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteProductSupplies(id_product_supplies);
    }
  });
}

function DeleteProductSupplies(id_product_supplies) {
   let me = this;
  let url = this.url_base + "product/delete-product-supplies/" + id_product_supplies;
  axios({
    method: "delete",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.products_supplies.map(item => item.id_product_supplies).indexOf(response.data.result.id_product_supplies);
        me.products_supplies.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });

}


function UpdateProductSupplies(index) {
   let me = this;
  let url = this.url_base + "product/update-product-supplies";
  let data = this.products_supplies[index];
  axios({
    method: "post",
    data:data,
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });

}


</script>
